import { createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.error.cause.js";
import { ElMessageBox, ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message-box/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import { mixins } from "@/plugins/mixins";
export default {
  name: "dictionary",
  components: {},
  mixins: [mixins],
  data() {
    return {
      switchLoading: false,
      columns: [{
        type: 'expand',
        typeSlot: 'logExpand'
      }, {
        label: '字典分类名称',
        prop: 'name',
        align: 'left'
      }, {
        label: '字典分类编码',
        prop: 'code'
      }, {
        label: '描述',
        prop: 'remark'
      }, {
        label: '状态',
        customRender: props => {
          if (this.$hasPermission('dictionary_enable')) {
            return _createVNode(_resolveComponent("el-switch"), {
              "modelValue": props.row.enable,
              "onUpdate:modelValue": $event => props.row.enable = $event,
              "loading": this.switchLoading,
              "before-change": () => {
                return new Promise((resolve, reject) => {
                  this.changeEnabled(props.row, resolve, reject);
                });
              }
            }, null);
          } else {
            return props.row.enable ? '启用' : '停用';
          }
        }
      }],
      topButtons: [],
      linkButtons: [{
        name: '添加明细',
        code: 'dictionary_add',
        click: this.add,
        permissions: ['dictionary_add']
      }, {
        name: '编辑',
        code: 'dictionary_edit',
        click: this.editRow,
        permissions: ['dictionary_edit']
        // }, {
        //     name: '删除',
        //     code: 'dictionary_del',
        //     click: this.deleteRow,
        //     permissions: ['dictionary_del', this.initPermissionCode]
      }],

      // 搜索字段
      searchItems: [{
        prop: 'name',
        label: '字典分类名称'
      }, {
        prop: 'enable',
        label: '状态'
      }]
    };
  },
  methods: {
    add(row) {
      this.$router.push({
        path: '/dictionaryItemAdd',
        query: {
          categoryId: row.id
        }
      });
    },
    editRow(row) {
      this.$router.push({
        path: '/dictionaryEdit',
        query: {
          id: row.id
        }
      });
    },
    changeEnabled(row, resolve, reject) {
      this.switchLoading = true;
      const callBack = res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          return resolve(true);
        } else {
          ElMessage.error(res.message);
          return reject(new Error('Error'));
        }
      };
      if (row.enable) {
        this.$api.business.dictionary.disable({
          id: row.id
        }).then(callBack).finally(() => this.switchLoading = false);
      } else {
        this.$api.business.dictionary.enable({
          id: row.id
        }).then(callBack).finally(() => this.switchLoading = false);
      }
    },
    deleteRow(row) {
      ElMessageBox.confirm('确认要删除该数据字典分类吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.dictionary.del({
          id: row.id
        }).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    },
    editItem(row) {
      this.$router.push({
        path: '/dictionaryItemAdd',
        query: {
          categoryId: row.categoryId,
          id: row.id
        }
      });
    },
    itemChange(row) {
      this.switchLoading = true;
      const callBack = res => {
        if (res.code === 200) {
          this.$refs.tablePage.fetch();
          ElMessage({
            message: res.message,
            type: 'success'
          });
        } else {
          ElMessage.error(res.message);
        }
      };
      if (row.enable) {
        this.$api.business.dictionary.itemEnable({
          id: row.id
        }).then(callBack).finally(() => this.switchLoading = false);
      } else {
        this.$api.business.dictionary.itemDisable({
          id: row.id
        }).then(callBack).finally(() => this.switchLoading = false);
      }
    },
    delItem(row) {
      ElMessageBox.confirm('确认要删除该数据字典明细吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.dictionary.itemDel({
          id: row.id
        }).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    }
  },
  beforeMount() {}
};